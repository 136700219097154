
import { defineComponent, ref } from 'vue'
import Service from  '../types/service'

export default defineComponent({
    name: 'Services',
    setup() {
        const skills = ref<Service[]>([
            {
                imageUrl: require("../../src/assets/tech-tools/graphql.png"),
                id: "1",
                description: "GraphQL Logo",
            },
            {
                imageUrl: require("../../src/assets/tech-tools/flutter.png"),
                id: "1",
                description: "Flutter Logo",
            },
            {
                imageUrl: require("../../src/assets/tech-tools/vuejs.jpeg"),
                id: "1",
                description: "Vue JS",
            },
            {
                imageUrl: require("../../src/assets/tech-tools/typescript.png"),
                id: "1",
                description: "TypeScript Logo",
            },
            {
                imageUrl: require("../../src/assets/tech-tools/postgresql.png"),
                id: "1",
                description: "PostgreSQL Logo",
            },
            {
                imageUrl: require("../../src/assets/tech-tools/dart.png"),
                id: "1",
                description: "Dart Logo",
            },
            {
                imageUrl: require("../../src/assets/tech-tools/nodejs.png"),
                id: "1",
                description: "NodeJS Logo",
            },
            {
                imageUrl: require("../../src/assets/tech-tools/django.png"),
                id: "1",
                description: "Django Logo",
            },
            {
                imageUrl: require("../../src/assets/tech-tools/react.jpeg"),
                id: "1",
                description: "React Logo",
            },
            {
                imageUrl: require("../../src/assets/tech-tools/aws.jpeg"),
                id: "1",
                description: "AWS Logo",
            },
            {
                imageUrl: require("../../src/assets/tech-tools/firebase.png"),
                id: "1",
                description: "Firebase Logo",
            },
            {
                imageUrl: require("../../src/assets/tech-tools/oracle.png"),
                id: "1",
                description: "Oracle Cloud Logo",
            },
            {
                imageUrl: require("../../src/assets/tech-tools/python.png"),
                id: "1",
                description: "Python Logo",
            },
            {
                imageUrl: require("../../src/assets/tech-tools/mysql.png"),
                id: "1",
                description: "MySQL Logo",
            },
            {
                imageUrl: require("../../src/assets/tech-tools/docker.png"),
                id: "1",
                description: "Docker Logo",
            },
            {
                imageUrl: require("../../src/assets/tech-tools/javascript.png"),
                id: "1",
                description: "JavaScript Logo",
            },
            {
                imageUrl: require("../../src/assets/tech-tools/googlecloud.jpg"),
                id: "1",
                description: "Google Cloud Logo",
            },
            {
                imageUrl: require("../../src/assets/tech-tools/mongodb.png"),
                id: "1",
                description: "MongoDB Logo",
            },
            {
                imageUrl: require("../../src/assets/tech-tools/rive.png"),
                id: "1",
                description: "Rive Logo",
            },
            {
                imageUrl: require("../../src/assets/tech-tools/nest.png"),
                id: "1",
                description: "NestJS Logo",
            },
        ])
        return {skills}
    }

})
