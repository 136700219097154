<template>
  <div
    id="nav"
    class="navbar navbar-expand-lg navbar-default bg-primary shadow-0"
  >
    <router-link to="/"
      ><img
        src="./assets/rhino_logo_white.png"
        alt="RhinoSoft Logo"
        style="height: 70px"
    /></router-link>
    <div class="mt-5">
      <div class="container-fluid justify-content-end text-align-end text-light">
        <!-- <router-link to="/" class="px-3 text-light menu-item">Home</router-link> -->
        <a href="#approach" class="px-3 text-light menu-item">Approach</a>
        <a href="#clients" class="px-3 text-light menu-item">Clients</a>
        <a href="#technology" class="px-3 text-light menu-item">Technology</a>
        <a href="#contact" class="px-3 text-light menu-item">Contact</a>
      </div>
    </div>
  </div>
  <router-view />
</template>

<style lang="scss">
@import "./assets/_variables.scss";
@import "~mdb-vue-ui-kit/src/scss/index.pro.scss";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.menu-item:hover {
  border-bottom: 1px solid #008080;
  // background-color: red;
}

</style>
