
import { defineComponent, ref } from "vue";
import Client from "@/types/client";
import { MDBAnimation } from "mdb-vue-ui-kit";

export default defineComponent({
  name: "FeaturedClients",
  components: {
    MDBAnimation,
  },
  setup() {
    const clients = ref<Client[]>([
      {
        imageUrl: require("../../src/assets/clients-logos/SCHS.png"),
        description: "Saudi Commission For Health Specialties",
        id: "1",
        delay: 100,
      },
      {
        imageUrl: require("../../src/assets/clients-logos/hla.png"),
        description: "Healthcare Leadership Academy",
        id: "2",
        delay: 200,
      },
      {
        imageUrl: require("../../src/assets/clients-logos/aumet_logo.png"),
        description: "Aumet logo",
        id: "1",
        delay: 100,
      },
      // {
      //   imageUrl: require("../../src/assets/clients-logos/mhamo_logo.png"),
      //   description: "mhamo logo",
      //   id: "2",
      //   delay: 200,
      // },
      {
        imageUrl: require("../../src/assets/clients-logos/battikhi_ortho_logo.png"),
        description: "Battikhi Orthodontics Logo",
        id: "1",
        delay: 100,
      },
      {
        imageUrl: require("../../src/assets/clients-logos/grand_stores_logo.jpeg"),
        description: "Grand Stores Logo",
        id: "2",
        delay: 200,
      },
      {
        imageUrl: require("../../src/assets/clients-logos/bilforon_logo.png"),
        description: "Bilforon Logo",
        id: "2",
        delay: 200,
      },
      // {
      //   imageUrl: require("../../src/assets/clients-logos/cloud_7_logo.png"),
      //   description: "Cloud 7 logo",
      //   id: "1",
      //   delay: 100,
      // },
      // {
      //   imageUrl: require("../../src/assets/clients-logos/Kerten_logo.jpeg"),
      //   description: "Kerten Logo",
      //   id: "2",
      //   delay: 200,
      // },
      {
        imageUrl: require("../../src/assets/clients-logos/icoffee_logo.png"),
        description: "iCoffee",
        id: "1",
        delay: 100,
      },
      {
        imageUrl: require("../../src/assets/clients-logos/zalatimo_logo.png"),
        description: "Zalatimo Logo",
        id: "2",
        delay: 200,
      },
      {
        imageUrl: require("../../src/assets/clients-logos/dada_logo.jpeg"),
        description: "Dada logo",
        id: "2",
        delay: 200,
      },
    ]);
    return {
      clients,
    };
  },
});
