
import { defineComponent } from "vue";
import { MDBInput } from "mdb-vue-ui-kit";

export default defineComponent({
  name: "Hello",
  components: {
    MDBInput,
  },
});
